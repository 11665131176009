<template>
  <div v-if="myMBTI" class="result-page">
    <header class="flex-container control-bar">
      <div class="flex-container">
        <img src="/image/result/page.png" alt="새 페이지 아이콘" class="page-icon" />
        <span>스낵고 생활기록부_{{result[myMBTI].name}}</span>
      </div>
      <div class="flex-container">
        <img src="/image/result/버튼1.png" alt="최소화" />
        <img src="/image/result/버튼2.png" alt="최대화" />
        <img src="/image/result/버튼3.png" alt="창닫기" />
      </div>
    </header>
    <div class="inner">
      <div class="flex-container logo">
        <img src="/image/result/logo.png" alt="스낵고 로고" id="logo" />
        <img src="/image/result/home.png" alt="home | logout" id="home" />
      </div>
      <h1>생활기록부</h1>
      <img
        :src="`/image/result/결과지-${result[myMBTI].name}.png`"
        :alt="result[myMBTI].name"
        class="profile-img"
      />
      <h1 class="result-name" v-html="result[myMBTI].name"></h1>
      <h2 class="sub-title">1. 인적·학적사항</h2>
      <section class="result-container" style="text-align: justify; word-break:keep-all;">
        <ul>
          <li v-for="li in result[myMBTI].intro" :key="li">{{li}}</li>
        </ul>
      </section>
      <h2 class="sub-title">2. 행동특성사항</h2>
      <section class="result-container" v-html="result[myMBTI].description"></section>
      <div class="flex-container">
        <h2 class="sub-title">3. 수상·활동경력</h2>
        <h2 class="sub-title">4. 추천학과</h2>
      </div>
      <div class="flex-container">
        <section class="result-container">
          <ul>
            <li v-for="li in result[myMBTI].awards" :key="li" style="word-break:keep-all;">{{li}}</li>
          </ul>
        </section>
        <section
          class="result-container"
          style="word-break:keep-all; letter-spacing: -1px;"
        >{{result[myMBTI].school}}</section>
      </div>
      <h2 class="sub-title">5. 교우관계</h2>
      <section
        class="result-container"
        v-html="result[myMBTI].friendship"
        style="text-align: justify;"
      ></section>
      <div class="flex-container">
        <h2 class="sub-title">6. 친한친구</h2>
        <h2 class="sub-title">7. 상극인친구</h2>
      </div>
      <div class="flex-container">
        <div class="friend-profile">
          <img
            :src="`/image/result/결과지-${result[myGoodPartner].name}.png`"
            :alt="result[myGoodPartner].name"
            class="result-partner"
          />
          <h3 class="partner-title" v-html="result[myGoodPartner].name"></h3>
        </div>
        <div class="friend-profile">
          <img
            :src="`/image/result/결과지-${result[myBadPartner].name}.png`"
            :alt="result[myBadPartner].name"
            class="result-partner"
          />
          <h3 class="partner-title" v-html="result[myBadPartner].name"></h3>
        </div>
      </div>
      <section v-if="myBadPartner">
        <h2
          class="sub-title"
          :style="{'text-align': 'center', 'font-weight': '500', margin: '50px auto -10px'}"
        >결과 공유하기</h2>
        <div class="share-box">
          <a class="icon" @click.prevent="copyURL">
            <picture>
              <img src="/image/result/결과지-링크@2x.png" alt="링크 공유하기" />
            </picture>
          </a>
          <a class="icon" @click="kakao" id="kakao-link-btn">
            <picture>
              <img src="/image/result/결과지-카카오톡@2x.png" alt="카카오톡 공유하기" />
            </picture>
          </a>
          <a
            class="icon"
            target="_blank"
            onclick="window.open(this.href,'targetWindow',`toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=600px,height=800px`); return false;"
            :href="`https://twitter.com/intent/tweet?url=https://snackgotest-ns.com/result/${myMBTI}`"
          >
            <picture>
              <img
                src="/image/result/결과지-트위터@2x.png"
                alt="트위터 공유하기"
                :style="{'border-radius' : '0px'}"
              />
            </picture>
          </a>
          <a
            class="icon"
            target="_blank"
            onclick="window.open(this.href,'targetWindow',`toolbar=no, location=no, status=no, menubar=no, scrollbars=no, resizable=no, width=600px, height=800px `);return false;"
            :href="`https://www.facebook.com/sharer/sharer.php?u=https://snackgotest-ns.com/result/${myMBTI}`"
          >
            <picture>
              <img src="/image/result/결과지-페이스북@2x.png" alt="페이스북 공유하기" />
            </picture>
          </a>
          <div
            class="icon line-it-button"
            data-lang="ko"
            data-type="share-c"
            data-ver="3"
            :data-url="`https://snackgotest-ns.com/result/${myMBTI}`"
            data-color="default"
            data-size="large"
            data-count="false"
            style="display: none;"
          ></div>
        </div>
        <h5>현재까지 {{numberWithCommas(count)}}명이 참여했어요!</h5>
      </section>
      <button class="result" @click="goHome">테스트 다시하기</button>
    </div>
  </div>
</template>
<script>
import copy from "copy-to-clipboard";
export default {
  name: "Result",
  data() {
    return {
      myMBTI: null,
      count: 0,
      result: {
        intj: {
          name: "자갈치",
          intro: ["고소하고 짭짤한 맛의 해물스낵", "스낵고 괴짜"],
          description:
            "혼자 있는 걸 좋아하고 즐기는 편임. 하지만 필요할 때는 어쩔 수 없이 나서서 리더 역할을 종종 맡는 모습이 관찰됨. 계획을 세우고 지키는 쪽이 훨씬 편하며 사실과 원리원칙을 중요시 해서 감정에 휘둘리는 것을 꺼려함. 생각이 많고 무언가를 다각도로 해석하는 걸 좋아하는 사고가 깊은 학생임. 비현실적 이상주의자이자 동시에 비판적인 냉소주의자 기질이 있음.<br/><br/>[특이사항]<br/>스낵고에서 말빨로 유명하며, 교내 토론대회에서 양파링과 피 튀기는 토론을 한 끝에 우승을 거머쥠.",
          awards: ["교내 토론대회 1등", "토론동아리 서기"],
          school: "산업공학과, 전자전기공학과",
          friendship:
            "사람들과 깊게 친해지는데 오래 걸리는 편의 학생임. 타인의 일에 관심 없고, 가십과 루머도 좋아하지 않아 소수의 진짜 친구만을 두는 편임. 다만 아니다 싶으면 손절도 잘 하는 편으로 보임. 고민상담을 해 줄 때는 관심있는 척 하지만 살실은 진심으로 공감하지 못함. 그렇지만 친한 사람들은 살뜰하게 챙겨주는 츤데레!",
          good: "ESTP",
          bad: "ESFJ"
        },
        intp: {
          name: "오징어집",
          intro: ["담백하고 고소한 버터구이맛 해물스낵", "스낵고 독서왕"],
          description:
            "논리왕에 지식에 대한 추구가 강한 편의 학생이지만 정작 책 읽고 공부하기는 귀찮아 일단 나중으로 미루는 기질이 관찰됨. 팩폭 그만하라는 말을 자주 들으며, 수다스러운 편은 아니지만 본인의 관심사가 나오면 급격히 말이 많아지는 모습이 보임. 완벽주의로 보이지만 정작 발등에 불 떨어지기 전까지 미루는 편임. 그러나 맡은 일은 확실히 해내는 믿음직스러운 학생임.<br/><br/>[특이사항]<br/>미루고 미룬 작문 수행평가 제출일 하루 전 과제를 시작했지만 밤을 새워 엄청난 퀄리티의 결과물을 제출함.",
          awards: ["독서동아리 부장"],
          school: "철학과, 수학과",
          friendship:
            "친구는 있지만 혼자 있는 쪽을 선호하며 행복감을 느낌. 많지는 않아도 있는 친구들과는 정말 잘 지내는 편임. 인간 관계에 있어서 조금 계산적인 면이 보이지만, 좁고 깊은 관계를 선호함.종종 다른 사람의 고민을 들어주는 모습이 보이지만, 사실 속으로 멍 때리고 있을 확률이 높음. 공감과 위로는 못하는 편이지만 관심사가 잘 통하면 진지한 대화로 이어지는 편.",
          good: "ENFJ",
          bad: "INFP"
        },
        entp: {
          name: "양파링",
          intro: [
            "산뜻한 양파맛과 담백한 감자맛이 일품인 인기스낵",
            "스낵고 최고 인기남"
          ],
          description:
            "자존심 강하고 고집도 세지만 자기합리화도 굉장히 뛰어난 학생임. 반격을 당하면 멀쩡한 척 하지만 사실은 속으로 부들거리고 있을 확률이 상당함. 계획에 따라 생활하는데 약하고 미루기 대장이지만, 어쨌든 맡은 바 일은 확실하게 해내는 믿음직스러운 학생임. 본인이 완벽하고 멋지다고 생각하며 자존감이 높은 학생임. 반복적이고 단순한 일 보다는 새로운 아이디어를 내는 창의적이고 다이나믹한 일에 흥미를 보임.<br/><br/>[특이사항]<br/>교내 토론대회에서 자갈치의 의견을 수용하지 않아 2등이 되었지만, 본인은 아직도 절대 지지 않았다고 생각함.",
          awards: ["교내토론대회 2등", "토론동아리 부장"],
          school: "법학과, 언론홍보학과",
          friendship:
            "ONLY 마이웨이, 겉은 인싸지만 속은 아싸인 것으로 생각됨. 다른 친구들과 밖에 나가면 잘 놀고 들어오지만 집에 있는 것도 좋아하는 집순이 기질이 보임. 친한 사람들 사이에서는 웃기다는 소리를 많이 듣는 개그맨임. 본인에게 잘해주는 사람에게 본인도 베풀려고 노력하는 의외의 따뜻한 면모가 돋보임.",
          good: "ESTJ",
          bad: "ENFJ"
        },
        entj: {
          name: "포테토칩곱창이핫해",
          intro: [
            "숯불구이 맵단짠 정석 곱창맛 감자칩",
            "스낵고 학생회 집행부장"
          ],
          description:
            "피해 주는 것, 피해 받는 것 모두 싫어해 혼자 많은 바를 척척 해내는 학생임. 답답하면 혼자 나서서 결국 일을 다 처리해버리는 편으로 남에게 잘 의지하지 않는 모습을 보임. 삶이 늘 바쁘지만 머리가 좋고 일머리가 좋아서 금방금방 주어진 일을 확실하게 해냄. 매사에 자신감이 넘치고 목표와 계획이 확실한 학생이라 믿음직스러움. 본인도 그걸 알아서 자기애가 강함.<br/><br/>[특이사항]<br/>전교생 상벌점 통계 정리를 본인이 답답하다는 이유로 혼자 다 끝냄. 이 일은 아직도 스낵고 학생회의 전설로 불리고 있음.",
          awards: ["학생회 공로상", "前 학생회 집행부원", "現 학생회 집행부장"],
          school: "경영학과, 경찰학과",
          friendship:
            "타인에게 의도치 않은 상처를 줄 때가 있어 조금 보완할 필요가 있음. 일 못 하는 사람들과 친해지는 것을 어려워 할 가능성이 있음. 친구에게 감정적인 공감은 어렵지만 객관적인 잘잘못을 가리는 것에서는 탁월한 편. 본래 외향적이라 새로운 사람을 사귀는 것에는 호의적인 편임.",
          good: "ESTP",
          bad: "ESFJ"
        },
        infj: {
          name: "고구마깡",
          intro: ["고구마로 만든 달콤한 스낵", "스낵고 모든 고민은 나에게로"],
          description:
            "위 학생은 평소에는 조용하나 수련회, 뒤풀이 등에서는 가끔 튀고 싶어하는 내성적인 관종 유형으로 보임. 신중한 완벽주의자 기질이 있어 늘 본인만의 계획이 있고, 행동 전에 늘 시뮬레이션을 돌려보는 편임. 생각이 많은 편으로, 감수성이 되게 풍부하고 혼자 망상을 하는 일도 잦음. 새로운 사람 만나고 새로운 일 시작하는 것을 그닥 선호하는 편은 아님.<br/><br/>[특이사항]<br/>교내 또래 상담사로 활동하며 친구의 고민을 들어주다 본인이 더 많이 울어 친구가 달래줬다고 함.",
          awards: ["교내 또래상담위원"],
          school: "심리학과, 교육과",
          friendship:
            "본인이 좋아하는 친구한테는 정말 잘해주지만 아니다 싶은 친구한테는 칼같은 면이 보임.상대가 자기가 정해 놓은 선을 넘으면 마음의 문을 닫아버리는 때가 있음. 본인의 속마음과 행동이 다를 때가 많다고 스스로 생각함. 좋아하는 사람이 있으면 본인에게 먼저 다가와주는 쪽을 항상 기대하는 모습을 보임.",
          good: "ESFP",
          bad: "ENTJ"
        },
        infp: {
          name: "별따먹자",
          intro: [
            "어른,아이 모두 좋아하는 고소한 쌀과자",
            "스낵고 숨겨진 춤꾼"
          ],
          description:
            "심리테스트, mbti테스트가 나오면 일단 무조건 하고 보는 편임. 관종이지만 막상 관심을 받으면 어색해하고 어쩔 줄 모르는 모습이 관찰됨. 끈기 없고, 계획적이지 못해 벼락치기를 하는 편이지만 막상 하면 잘 해내는 학생임. 집에 있을 때면 연락은 잘 안되는 편이고, 혼자 있는 것을 선호할 때가 있음. 가끔은 우울한 모습을 보이는데, 본인의 우울한 감정을 싫어하지 않는 편임. 사람들한테 상처 주는 것도, 받는 것도 정말 싫어함.<br/><br/>[특이사항]<br/>독서동아리 부원으로 동아리 부장인 오징어집의 TMI를 잘 들어줌. 하지만 귀기울여 듣는 것 같지는 않음.",
          awards: ["교내 오케스트라 부원", "독서동아리 부원"],
          school: "문예창작학과, 디자인학과",
          friendship:
            "남이 나를 어떻게 생각할지 늘 고민하고 걱정하는 모습이 보임. 인간 관계에 조금 예민하게 반응하는 편이라 본인을 소중하게 대해줬으면 함. 친구랑 있는 게 좋지만 혼자만의 시간도 중요하게 생각하는 학생임. 친구의 얘기를 잘 들어주지만 속으로 딴 생각 하는 모습이 종종 관찰됨.",
          good: "ISTJ",
          bad: "ISTP"
        },
        enfj: {
          name: "옥수수깡",
          intro: [
            "누구나 좋아하는 고소한 군옥수수맛 스낵",
            "스낵고 인간 리트리버"
          ],
          description:
            "열정 뿜뿜 리더형. 사람들을 이끄는 데 행복을 느낌. 말을 예쁘게 하고, 감수성이 풍부한 편이지만 귀가 조금 얇다는 특징이 있음. 주변 사람을 많이 신경쓰고 잘해주려고 하는 마음이 따뜻한 학생임. 무리 안에 속해있으면 잘 지내지만 마이웨이 기질도 조금 있는 것으로 보임.컨디션이 좋지 않으면 무의식중에 짜증을 낼 때도 있지만, 이후 후회하고 죄책감에 시달림.<br/><br/>[특이사항]<br/>스낵고 내에서 모르는 사람이 없어서 오죽하면 옥수수깡의 인스타 팔로워 수가 스낵고 전교생 수 라는 소리가 있음.",
          awards: ["교내 사회과학 동아리 부장", "학급 반장"],
          school: "사회학과, 정치외교학과",
          friendship:
            "사람을 되게 좋아해서 친한 사람도 아는 사람도 많은 편임. 처음 사람을 만나면 친해지고 싶어서 드릉드릉하는 골든 리트리버형임. 그치만 내가 혹시 기분을 상하게 했을까, 눈치도 많이 보는 편이지만 너무 선을 넘으면 할 말은 또 하는 면이 관찰됨.",
          good: "ISTP",
          bad: "INFJ"
        },
        enfp: {
          name: "닭다리",
          intro: [
            "웬만한 치킨전문점 부럽지 않은 바삭바삭 치킨맛 스낵",
            "스낵고 열정 만수르"
          ],
          description:
            "무계획, 즉흥적, 재밌고 간지나는 것을 좋아하는 자유로운 영혼임. 갑자기 꽂히면 누구보다 득달같이 달려들지만 그만큼 미루고 식는 속도도 빠른 편임. 세상이 조금 어두워도 예쁜 면을 보고 조금은 긍정적으로 살려고 하는 학생임. 다른 친구들은 본인을 한없이 해맑다고 생각하지만 스스로는 본인이 어두운 면도 있다고 생각함. 싫고 좋은 거 명확하고, 표현력과 감수성이 풍부한 매력있는 학생임.<br/><br/>[특이사항]<br/>수련회 때 장기자랑에 즉흥적으로 나와 상품을 다 쓸어감. 본인은 낯을 가린다고 생각하지만 스낵고 아무도 그렇게 생각 안함.",
          awards: ["교내 연극동아리 회장", "교내 UCC대회 우수상"],
          school: "연극영화과, 미디어학과, 광고홍보학과",
          friendship:
            "남들은 내가 우주 최강 인싸라고 하지만, 생존형 인싸에 가까움. 정말 친하고 마음을 나누는 사람은 보기보다 소수인 편임. 나를 사랑하고, 좋아하는지 늘 확인받고 싶어하는 기질이 있음. 정말 친한 친구랑 있으면 맥락없이 자기 이야기를 많이 하는 편임.",
          good: "ESFJ",
          bad: "ISFJ"
        },
        esfp: {
          name: "바나나킥",
          intro: ["항상 사랑받는 달콤한 바나나 맛 스낵", "스낵고 최고 연예인"],
          description:
            "사교적이고 긍정적이고 낙천적이고 영리하고 개방적인 학생임. 다소 충동적인 모습과 성격이 급한 모습을 보이는 오지라퍼지만, 막상 자존감은 높은 편으로 보임. 사람이 너무 좋고 어울리는 것도 너무 좋아하지만 막상 집 밖으로 나가는 건 귀찮아함. 하고 싶은 건 다 해야하는 성격이지만 뭐든 미루는 성향이 강한 편임.<br/><br/>[특이사항]<br/>스낵고에 안 친한 친구가 없어서 체육대회 때 전교생 대통합의 장을 만 들어냄.",
          awards: [
            "체육 교과우수상",
            "스낵고 축제 반 장기자랑 1위",
            "학생회 체육부장"
          ],
          school: "실용음악과, 관광학과",
          friendship:
            "엄청난 사교성으로 스낵고 모두와 원만한 관계를 유지하며 인간관계가 넓음. 사람의 장점을 보려고 노력하며 항상 평화를 유지하고자 함. 왁자지껄 모여서 떠드는 자리에는 무조건 참석하려고 하는 성향의 학생임.",
          good: "INTJ",
          bad: "ESFJ"
        },
        istj: {
          name: "새우깡",
          intro: ["고전의 맛 No1. 국민스낵", "스낵고 걸어다니는 플래너"],
          description:
            "요새 세상에 보기 드문, 원리원칙적이고 계획적인 학생임. 자신의 이야기를 하는 것도 별로고 타인의 이야기를 듣는 것에도 그렇게 흥미를 느끼지 못함. 말도 많지 않고 항상 신중하게 행동하려 하는 편인 학생이라 전형적인 책임감 많은 K-장녀 장남이 떠오름. 여행을 가면 계획은 무조건 본인이 나서서 다 짜는 편임. 협동하는 프로젝트보다 혼자서 휘리릭 끝내는 개인 과제를 선호하는 것으로 보임.<br/><br/>[특이사항]<br/>학생회 기획부원으로 스낵고 학생회의 모든 행사 일정은 새우깡의 머릿속에서 탄생함",
          awards: ["학생회 기획부장"],
          school: "세무회계과, 행정학과",
          friendship:
            "사람 많은 곳, 핫플을 가면 에너지를 뺏기는 기분이 듦. 말이 너무 많거나 감정이 풍부한 사람과는 잘 맞지 않는 편임. 즉흥적인 일들보다는 계획적이고 정돈된 일을 선호해 약속을 맘대로 어기는 사람들을 꺼려함. 힘들어 보이면 가만히 내버려 두는 것이 최고의 방법.",
          good: "ISFP",
          bad: "ESFJ"
        },
        isfj: {
          name: "꿀꽈배기",
          intro: ["배배꼬인 모양 달콤한 꿀 맛 스낵", "스낵고 봉사왕"],
          description:
            "하자고 하면 거의 다 해주고 거절, 부탁을 잘 못하는 편의 학생임. 게으른 완벽주의자 성향이 있어 계획을 세우고 그대로 지켜야 마음이 편함. 상황에 따라 외향, 내향 왔다갔다하는 편이라 가끔 본인도 본인을 잘 모르겠음. 배려심도 많고 공감도 잘해주고 사람들이랑 어울리는 거 좋아하는 편임. 그렇지만 또 혼자 노는 것도 되게 좋아함. 사람들은 내 인생이 노잼이라지만 난 나름대로 내 인생 재밌고 좋음.<br/><br/>[특이사항]<br/>이미 봉사시간을 다 채웠음에도 불구하고 같이 봉사가자는 친구의 부탁을 거절하지 못해 스낵고에서 봉사시간을 가장 많이 채움.",
          awards: ["학급 부반장"],
          school: "유아교육과, 아동심리학과",
          friendship:
            "본인을 편안하게 해주는 쪽의 사람, 이벤트 잘 챙겨주고 표현을 잘해주는 학생들을 좋아함. 외로움은 좀 타는데 그렇다고 많은 사람이랑 놀고 싶지는 않음. 티는 안 나지만 사실은 인간 관계에서 스트레스를 많이 받는 편임.",
          good: "INTP",
          bad: "ISFP"
        },
        isfp: {
          name: "감자깡",
          intro: ["오래 사랑받는 바삭한 감자스낵", "스낵고 대표 순둥이"],
          description:
            "만사가 귀찮고 행동이 느린데다 미루는 것도 잘하는 집순이로 보임. 대부분 무기력하지만 한번 꽂히면 제대로 하는 편이고 개인주의를 선호함. 다른 사람들의 기분을 잘 알아내지만 감정표현이 서툴어서 제대로 전달은 못함. 거절을 잘 못하고 양보를 잘 하지만 정작 속마음은 남에게 잘 이야기 하지 않음. 알고보면 조용한 관종이라서 관심 받는 거 싫어하는 것처럼 보이지만 속으로는 좋아함.<br/><br/>[특이사항]<br/>평소 무기력해 학교생활에 관심이 없는듯 보이지만, 교내 사생대회가 열렸다하면 엄청난 집중력으로 상을 휩씀.",
          awards: ["교내 사생대회 우수상"],
          school: "미용학과, 초등교육과",
          friendship:
            "친구랑 막상 나가면 잘 놀지만 약속 취소되면 기쁠 때가 많음. 사람들은 다들 내가 착하다고 하는데 사실은 본인이 이기적이라고 생각함. 인간관계에 생각보다 신경을 많이 쓰는 편이고 내 시간과 공간을 침범하지 말아줬으면 함. 본인에게 칭찬을 많이 해주고 잘 반응해주는 사람을 좋아하는 편임.",
          good: "INFJ",
          bad: "INTP"
        },
        estj: {
          name: "포스틱",
          intro: [
            "1980년생 바삭함 끝판왕 포스 감자튀김",
            "스낵고등학교 포스 뿜뿜 전교회장"
          ],
          description:
            "고집이 센 편이며, 현실적이고 이성적이며 직설적인 기질이 관찰됨. 호불호가 확실한 편으로, 뭐든 딱딱 떨어지는 확실한 쪽을 선호함. 시간 약속을 어기는 것, 즉흥적으로 계획을 변경하는 일을 상당히 꺼려함. 하나의 목표를 정해놓았다면 그 목표를 이루기 위해 한 우물만 파는 성향이 있음. 타인과 있을 때와 혼자 있을 때의 본인 성격이 각각 다르다고 생각하는 편임.<br/><br/>[특이사항]<br/>전교회장으로서 논리적으로 교장 선생님을 설득해 스낵고 학교시설을 전면 개혁하는데 이바지함",
          awards: ["스낵고 전교회장", "반크 동아리"],
          school: "기계학과, 행정학과",
          friendship:
            "타인에게 관심이 없고 앞과 뒤가 같아서 앞에서도 못할 이야기는 뒤에서도 하지 않음. 공감보다는 문제 상황을 분석한 뒤 누구의 잘못인지 객관적으로 판단하는 성향이 앞섬. 대체적으로 일 빠릿빠릿하게 잘 하는, 똑똑한 사람을 더 선호함. 인정받는 것을 좋아해서 사생활을 존중하며 표현해주는 사람을 좋아하는 편임.",
          good: "INFP",
          bad: "INFJ"
        },
        istp: {
          name: "쫄병스낵",
          intro: [
            "장기판 쫄병 모양의 한 입에 먹기 좋은 스낵",
            "스낵고 백과사전"
          ],
          description:
            "만사를 귀찮아하여 항상 본인의 노력을 최대한으로 절약하려고 함. 주류에 속하고 싶지도 않고 말 많고 자기 자랑 많은 사람들을 정말 싫어하는 편임. 관종 기질이 있어서 관심을 받는건 좋지만 또 시끄러운 건 선호하지 않음. 티는 안 내지만 본인이 짱이고 본인이 제일 잘났다고 생각함. 타인에게 관심이 없고 자신의 이야기도 많이 하지 않는 편임. 대체적으로 무미건조함.<br/><br/>[특이사항]<br/>귀찮음 때문에 학교 생활에 적극적으로 참여하지 않아 수상, 활동경력이 없음.",
          awards: ["없음"],
          school: "소방안전학과, 경찰행정학과",
          friendship:
            "남에게 관심이 많이 없는 편이라 타인이 본인에게 지나친 감정을 털어놓는 것을 꺼려함. 시끄러운 것을 선호하지 않아 너무 감정적인 사람과의 대화에 피곤해하는 모습을 보임. 친해지면 정말 활발하고 말도 많지만 그렇지 않은 사람이라면 입을 꾹 다무는 기질이 있음. 타인이 본인의 욕을 하든 말든 그닥 신경 안 쓰는 편임.",
          good: "ENFJ",
          bad: "INTJ"
        },
        esfj: {
          name: "프레첼",
          intro: ["단짠단짠이 중독적인 카라멜 맛 스낵", "스낵고 핵인싸"],
          description:
            "철저하고 질서정연한 학생으로 계획을 잘 세우고 그에 맞춰 생활하고자 노력함. 공감능력이 뛰어나 평소 남의 일도 내 일처럼 생각하고 고민상담을 해주는 편임. 그러나 쓸데없이 걱정이 너무 많은 면모도 있어 이를 조금 고칠 필요가 있음. 핵인싸기질이 있어서 사회생활도 잘하고 자연스럽게 분위기를 풀어나가는 학생임.<br/><br/>[특이사항]<br/>야자시간에 본인이 상담한 친구의 고민에 대한 걱정으로 사색에 잠길 때가 많음.",
          awards: ["교내 선행상", "교내 상담 동아리 부원"],
          school: "항공서비스학과, 물리치료학과",
          friendship:
            "사람을 좋아하는 편이라 본인의 사람들이 힘들면 본인도 같이 힘들어지는 편임. 인간관계가 틀어지면 받는 스트레스가 큰 편이고 타인의 눈치를 조금 보는 편임. 새로운 사람과 만나서 친해지는 것을 정말 좋아하고 배려왕으로 소문남.",
          good: "ISTP",
          bad: "ESTJ"
        },
        estp: {
          name: "인디안밥",
          intro: ["고소한 옥수수 맛 영양만점 스낵", "스낵고 대표 자기 사랑꾼"],
          description:
            "술자리, 내기, 노는 것, 스릴 등 흥미 위주의 일들을 선호하며 즉흥적이고 자유분방함. 약간 관심을 필요로 하는 성향이 있으며 리더십도 뛰어난 학생임. 하고 싶은 일은 다 하고 살고 싶지만 못해도 그냥 까먹는 편으로 보임. 약간의 다혈질적 성향이 있음. 대충 살고 눈치, 스트레스에서 자유로운 편이라 사는 대로 살아가는 기질이 강함. 순발력과 임기응변 능력이 있어서 눈치도 빠르고 감정 표현에도 능숙한 개방적인 학생임.<br/><br/>[특이사항]<br/>학급 부반장으로 반 분위기 메이커를 담당하고 학급 반장인 옥수수깡이 잘 어울리지 못하는 친구들을 챙기는 편임.",
          awards: ["축구부 주장", "학급 부반장", "모의 창업동아리 회장"],
          school: "체육학과, 경영학과",
          friendship:
            "본인을 통제하려고 하는 사람에게 적대적인 편임. 타인에게 그닥 관심이 없는 편이고 그에 대해 생각하는 것도 귀찮아 보임. 모임에서는 항상 본인이 분위기를 주도하지만 가끔 혼자 있을 때에는 외로움을 조금 느끼는 편이라고 함.",
          good: "ENTJ",
          bad: "ENFP"
        }
      }
    };
  },
  metaInfo() {
    return {
      title: `스낵고 생활기록부_${this.result[this.myMBTI].name}`,
      meta: [
        {
          vmid: "og:title",
          property: "og:title",
          content: `스낵고 생활기록부_${this.result[this.myMBTI].name}`
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: `https://snackgotest-ns.com/image/result/결과지-${
            this.result[this.myMBTI].name
          }.png`
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: `https://snackgotest-ns.com/image/result/${this.myMBTI}`
        }
      ]
    };
  },
  metaInfo() {
    return {
      title: this.result[this.myMBTI].name,
      description: this.result[this.myMBTI].description,
      meta: [
        {
          vmid: "og:title",
          property: "og:title",
          content: this.result[this.myMBTI].text
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: `https://snackgotest-ns.com/image/result/결과지-${
            this.result[this.myMBTI].name
          }.png`
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.result[this.myMBTI].description
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: `https://snackgotest-ns.com/result/${this.myMBTI}`
        }
      ]
    };
  },
  computed: {
    myGoodPartner() {
      return this.result[this.myMBTI].good.toLowerCase();
    },
    myBadPartner() {
      return this.result[this.myMBTI].bad.toLowerCase();
    }
  },
  created() {
    this.myMBTI = this.$route.params.id;
    if (this.result[this.myMBTI] === undefined) {
      this.$router.push({
        name: "Home"
      });
    }
    const docRef = this.$firestore.collection("user").doc("snackgotest");
    docRef.get().then(doc => {
      const { finishCount } = doc.data();
      this.count = finishCount;
    });
  },
  mounted() {
    document.body.style.backgroundColor =
      this.$route.name === "Result" ? "#c1c1c1" : "#004f32";
  },
  methods: {
    goHome() {
      this.$router.push({
        name: "Home"
      });
    },
    kakao() {
      Kakao.Link.sendDefault({
        objectType: "feed",
        content: {
          title: this.result[this.myMBTI].name,
          description: this.result[this.myMBTI].description,
          imageUrl: `https://snackgotest-ns.com/image/result/결과지-${
            this.result[this.myMBTI].name
          }.png`,
          link: {
            mobileWebUrl: `https://snackgotest-ns.com/result/${this.myMBTI}`,
            webUrl: `https://snackgotest-ns.com/result/${this.myMBTI}`
          }
        },
        buttons: [
          {
            title: "테스트 시작!",
            link: {
              mobileWebUrl: `https://snackgotest-ns.com/result/${this.myMBTI}`,
              webUrl: `https://snackgotest-ns.com/result/${this.myMBTI}`
            }
          }
        ]
      });
    },
    copyURL() {
      copy(`https://snackgotest-ns.com/result/${this.myMBTI}`);
      window.alert("주소를 복사했습니다.");
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
};
</script>
<style lang="scss" scope>
.result-page {
  margin: 20px auto;
  max-width: 400px;
  font-family: ChosunGu;
  text-align: center;
  background-image: linear-gradient(to bottom, #78adf4, #bcdaf5, #c3dff5 30%);
  padding: 10px 18px;
  border: 1px solid black;
  border-radius: 10px;
  .inner {
    border: 1px solid black;
    background-color: #ffffff;
    padding: 20px;
    h1 {
      font-size: 24px;
      font-weight: 400;
      &.result-name {
        font-weight: bold;
        margin-top: 12px;
      }
    }
    h2.sub-title {
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      width: 100%;
    }
    .flex-container {
      gap: 10px;
      &.logo {
        justify-content: space-between;
        align-items: flex-start;
        height: 0;
        #logo {
          width: 75px;
          transform: translate(-15px, -15px);
        }
        #home {
          width: 114px;
          transform: translate(4px, -20px);
        }
      }
    }
    .profile-img {
      max-width: 180px;
      margin: auto;
    }
    .result-container {
      padding: 10px;
      width: 100%;
      margin-bottom: 20px;
      line-height: 1.5;
      text-align: left;
      font-size: 14px;
      border: 1px solid black;
    }
    ul {
      margin: 0;
      list-style-type: none;
      padding: 0;
      li {
        font-size: 14px;
        letter-spacing: -1px;
      }
    }
    h3.partner-title {
      font-weight: 400;
      margin-top: 10px;
      letter-spacing: -2px;
      word-break: keep-all;
      font-size: 17px;
    }
    .share-box {
      display: flex;
      justify-content: center;
      margin: 24px;
      text-align: center;
      .icon {
        cursor: pointer;
        margin: 0 7px;
      }
      img {
        width: 46px;
        border-radius: 24px;
      }
    }
    h5 {
      margin-bottom: 40px;
      font-size: 18px;
    }
  }
}
.control-bar {
  align-items: center;
  justify-content: space-between;
  padding: 5px 0 7px;
  letter-spacing: -0.7px;
  span {
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    width: 100%;
    max-width: 200px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media (max-width: 400px) {
      width: 180px;
    }
  }
  img {
    max-width: 30px;
    margin-left: 3px;
  }
  .page-icon {
    width: 14px;
    position: relative;
    left: -3px;
    margin-right: 4px;
  }
}
.result-partner {
  width: 100%;
}
</style>